.root {
  display: flex;
  position: relative;
  margin-right: 50px;
}

.root:hover {
  cursor: pointer;
}

.root.isMobile {
  margin-right: 10px;
}

.counter {
  position: absolute;
  left: 15px;
  bottom: 0;
  background: var(--secondary);
  border-radius: 19px;
  min-width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  padding: 3px;
}
