.root {
  outline: none;
  background: var(--white);
  border-radius: 5px;
  border: 1px solid var(--blue-light);
  color: var(--blue-light);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 14px 29px 14px 20px;
  font-family: Stolzl;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.root:hover {
  cursor: pointer;
  color: var(--button-hover);
  border: 1px solid var(--button-hover);
}

.returnButtonIcon path {
  stroke: var(--blue-light);
}

.root:hover .returnButtonIcon path {
  stroke: var(--button-hover);
}

.root.fullWidth {
  width: 100%;
  box-sizing: border-box;
}

.root.isMobile {
  margin-top: 20px;
}
