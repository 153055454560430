.selectedUserList {
  display: flex;
  max-width: 400px;
  flex-wrap: wrap;
  gap: 7px;
}

.selectedUserWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
}

.selectedUser {
  background: #e1f7ff;
  padding: 6px 7px;
  color: var(--blue-light);
  display: flex;
  max-width: 172px;
  min-width: 172px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}

.selectedUserName {
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedUserIcon {
  width: 12px;
  height: 12px;
}

.selectedUserIcon path {
  stroke: var(--blue-light);
}

.selectedUserIcon:hover {
  cursor: pointer;
}
