.root {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.text {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: var(--blue-light);
}

.root .addNewAccountButton {
  width: auto;
}
