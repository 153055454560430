.root {
  width: 768px;
  height: 531px;
  background: var(--white);
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  box-sizing: border-box;
}

.root.isTabled {
  width: 90%;
  box-sizing: border-box;
}

.root.isMobile {
  width: 90%;
  height: 531px;
  box-sizing: border-box;
}

.closeIconWrapper:hover {
  cursor: pointer;
}

.closeIcon {
  position: absolute;
  top: 33px;
  right: 38px;
}

.root.isMobile .closeIcon {
  top: 25px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.root.isTabled .content {
  width: 100%;
  align-items: center;
}

.root.isMobile .content {
  width: 100%;
  align-items: center;
}
