.root {
  font-family: 'Stolzl';
  font-style: normal;
  outline: none;
}

.primary {
  background: var(--blue-light);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  border: none;
}

.primary:hover {
  cursor: pointer;
}

.primary.small {
  width: 214px;
  height: 50px;
  padding: 16px 28px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.primary.small:hover {
  background: var(--button-hover);
  cursor: pointer;
}

.root.fullWidth {
  width: 100%;
}

.primary.large {
  font-weight: 400;
  font-size: 18px;
  width: 100%;
  line-height: 22px;
  height: 48px;
}

.primary.large:hover {
  background: var(--button-hover);
  cursor: pointer;
}

.ghost {
  background: var(--white);
  border: 1px solid var(--blue-light);
  border-radius: 10px;
  color: var(--blue-light);
  box-sizing: border-box;
}

.ghost.small {
  padding: 11px 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ghost:hover.small {
  color: var(--button-hover);
  border: 1px solid var(--button-hover);
  cursor: pointer;
}

.ghost.large {
  padding: 19.5px 33px 19.5px 25px;
  color: var(--black);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid var(--blue-light);
}

.ghost.large.icon {
  fill: var(--blue-light);
}

.ghost:hover.large {
  color: var(--white);
  border: 1px solid var(--blue-light);
  background: var(--blue-light);
  cursor: pointer;
}

.content {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
}

.cancel {
  background: #f2f2f2;
  color: var(--black);
  border: 1px solid #f2f2f2;
  height: 41px;
  padding: 11px 18px;
  width: fit-content;
  border-radius: 5px;
}

.cancel:hover {
  background: #f2f2f2;
  color: var(--black);
  border: 1px solid #d6d6d6;
  height: 41px;
  padding: 11px 18px;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
}

.root .loadingOverride {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  margin-bottom: 0;
}

.root.primary .circularColor {
  color: var(--white);
}

.root.ghost .circularColor {
  color: var(--blue-light);
}
