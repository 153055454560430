.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.root.global {
  position: fixed;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.root.internal {
  position: static;
  top: auto;
  left: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circularProgress {
  color: var(--blue-light);
}

.title {
  margin-top: 6px;
}

.root.maxHeight {
  height: 100vh;
}
