.root {
  box-shadow: -34px 71px 32px rgba(147, 163, 169, 0.01),
    -19px 40px 27px rgba(147, 163, 169, 0.05),
    -9px 18px 20px rgba(147, 163, 169, 0.09),
    -2px 4px 11px rgba(147, 163, 169, 0.1), 0px 0px 0px rgba(147, 163, 169, 0.1);
}

.content {
  display: flex;
  flex-direction: column;
  padding: 17px 18px 20px 18px;
  background: var(--white);
  border-radius: 10px;
  width: 321px;
  box-sizing: border-box;
}

.addButton {
  display: flex;
  outline: none;
  border: none;
  background: var(--white);
  align-items: center;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding-left: 0;
  padding: 10px 30px 10px 10px;
}

.addIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addButton:hover {
  background: #e1f7ff;
  border-radius: 5px;
  cursor: pointer;
}

.addButton .addButtonIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e1f7ff;
  margin-right: 11px;
  border-radius: 24px;
}

.addButton .addButtonIcon svg path {
  fill: var(--blue-light);
}

.popoverData {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 12px;
  padding-bottom: 15px;
  margin-top: 0;
}

.personalAccountButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: var(--black);
  border: none;
  outline: none;
  background: var(--white);
  padding: 10px 30px 10px 10px;
  border-radius: 5px;
}

.personalAccountButton:hover {
  background: #e1f7ff;
  cursor: pointer;
}

.popoverDataNumber {
  margin-left: 5px;
  color: var(--blue-light);
}

.logoutButton {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: var(--white);
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--blue-light);
  padding-left: 0;
}

.logoutButton .logoutIcon {
  margin-left: 9px;
}

.logoutIcon path {
  stroke: var(--blue-light);
}

.logoutButton:hover {
  color: var(--button-hover);
  cursor: pointer;
}

.logoutButton:hover .logoutIcon path {
  stroke: var(--button-hover);
}

.isLoading {
  margin-left: 7px;
}

.popoverAddress {
  color: #6d6d6d;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  text-align: start;
  margin-top: 5px;
}
