.root {
  display: flex;
  width: 100%;
  padding: 20px 25px 20px 20px;
  min-height: 67px;
  box-sizing: border-box;
  flex-direction: column;
}

.root.red {
  border-radius: 10px;
  border: 1px solid rgba(231, 58, 95, 0.2);
  background: rgba(255, 219, 227, 0.47);
}

.root.red .title {
  color: var(--secondary);
}

.root.green {
  border-radius: 10px;
  border: 1px solid #26e375;
  background: #e5fff0;
}

.root.red .title {
  color: var(--secondary);
}

.root.yellow {
  border-radius: 10px;
  border: 1px solid #ffedc9;
  background: #fffaee;
}

.root.green .title,
.root.root.yellow .title {
  color: #090909;
}

.root.isMobile {
  padding: 15px 22px 15px 15px;
}

.iconWrapper {
  display: flex;
}

.icon {
  margin-right: 18px;
}

.root.green .icon path {
  fill: #00dc5c;
}

.root.yellow .icon path {
  fill: #faad14;
}

.root.isMobile .icon {
  margin-right: 10px;
}

.message {
  display: flex;
  align-items: center;
}

.moreDetailed {
  margin-left: auto;
  color: #090909;
  font-family: Stolzl;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.spoilerIcon {
  transform: rotate(0);
}

.spoilerIcon.up {
  transform: rotate(180deg);
}

.spoilerIcon path {
  stroke: #090909;
}

.spoilerIcon:hover {
  cursor: pointer;
}

.description {
  font-size: 14px;
  margin-left: 54px;
  margin-top: 20px;
}

.headingRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
