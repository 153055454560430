.root {
  width: 219px;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}

.root :global(.custom-select__control) {
  height: 50px;
  border: 1px solid #ececec;
  border-radius: 5px;
}

.root
  :global(.custom-select__control):global(.custom-select__control--is-focused) {
  border-color: #828282;
  box-shadow: none;
}

.root :global(.custom-select__single-value) {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}

.root :global(.custom-select__menu) {
  margin-top: 1px;
  padding: 13px 0;
  box-shadow: -34px 71px 32px rgba(147, 163, 169, 0.01),
    -19px 40px 27px rgba(147, 163, 169, 0.05),
    -9px 18px 20px rgba(147, 163, 169, 0.09),
    -2px 4px 11px rgba(147, 163, 169, 0.1), 0px 0px 0px rgba(147, 163, 169, 0.1);
  border-radius: 5px;
}

.root :global(.custom-select__menu) {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--black);
}

.root :global(.custom-select__indicator-separator) {
  display: none;
}

.root :global(.custom-select__option) {
  padding: 10px;
  color: black;
  font-size: 16px;
  background-color: #fff;
}

.root :global(.custom-select__option):hover {
  background-color: var(--blue-light);
}

.fullWidth {
  width: 100%;
}
