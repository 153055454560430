@font-face {
  font-family: 'Stolzl';
  src: url('fonts/Stolzl-Light.eot');
  src: local('Stolzl Light'), local('Stolzl-Light'),
    url('fonts/Stolzl-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/Stolzl-Light.woff2') format('woff2'),
    url('fonts/Stolzl-Light.woff') format('woff'),
    url('fonts/Stolzl-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('fonts/Stolzl-Regular.eot');
  src: local('Stolzl Regular'), local('Stolzl-Regular'),
    url('fonts/Stolzl-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Stolzl-Regular.woff2') format('woff2'),
    url('fonts/Stolzl-Regular.woff') format('woff'),
    url('fonts/Stolzl-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('fonts/Stolzl-Bold.eot');
  src: local('Stolzl Bold'), local('Stolzl-Bold'),
    url('fonts/Stolzl-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Stolzl-Bold.woff2') format('woff2'),
    url('fonts/Stolzl-Bold.woff') format('woff'),
    url('fonts/Stolzl-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('fonts/Stolzl-Medium.eot');
  src: local('Stolzl Medium'), local('Stolzl-Medium'),
    url('fonts/Stolzl-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/Stolzl-Medium.woff2') format('woff2'),
    url('fonts/Stolzl-Medium.woff') format('woff'),
    url('fonts/Stolzl-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Stolzl, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: #000000;
  --white: #fffffe;
  --blue-light: #41c8f4;
  --blue: #1251a0;
  --secondary: #e73a5f;
  --green: #005323;
  --gray: #828282;
  --button-hover: #0099cd;
}
