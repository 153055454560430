.root {
  display: flex;
  gap: 5px;
  border-radius: 5px;
  background: var(--white);
  align-items: flex-start;
  padding: 8px;
  width: 100%;
  flex-direction: column;
}

.root:hover {
  cursor: pointer;
  background: #e1f7ff;
}

.root.selected {
  background: #effbff;
}

.title {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--black);
}

.data {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--blue-light);
  padding-left: 5px;
}

.address {
  color: #6d6d6d;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  text-align: start;
}
