.root {
  top: 0;
  left: 0;
  width: 489px;
  height: 100vh;
  background: var(--white);
  display: flex;
  align-items: flex-end;
  position: absolute;
  border: none;
  border-radius: 0;
  flex-direction: column;
  padding-top: 70px;
  box-sizing: border-box;
  outline: none;
  overflow-y: auto;
}

.root.isMobile {
  width: 90%;
  padding-top: 58px;
  padding-bottom: 68px;
  overflow-y: auto;
}

.root.isTabled {
  padding-top: 58px;
  padding-bottom: 68px;
  overflow-y: auto;
}

.closeIconWrapper:hover {
  cursor: pointer;
}

.closeIcon {
  position: absolute;
  top: 48px;
  right: 36px;
}

.profileInfo {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 78px;
  border-radius: 78px;
  background: #e1f7ff;
  margin-bottom: 13px;
}

.profileName {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--black);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logoutButton {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: var(--blue-light);
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
  padding-left: 0;
  max-width: 387px;
  width: 387px;
  align-self: center;
  justify-content: center;
  border-radius: 10px;
  min-height: 48px;
  margin-bottom: 100px;
  margin-top: 50px;
}

.logoutButton .logoutIcon {
  margin-left: 9px;
}

.logoutIcon path {
  stroke: var(--white);
}

.logoutButton:hover {
  color: var(--button-hover);
  cursor: pointer;
}

.logoutButton:hover .logoutIcon path {
  stroke: var(--button-hover);
}

.root.isMobile .logoutButton {
  margin-top: 30px;
  max-width: 300px;
  min-height: 48px;
}

@media (min-height: 980px) {
  .root .logoutButton {
    margin-top: 34px;
  }
}

@media (max-height: 768px) {
  .root {
    padding-top: 58px;
    padding-bottom: 68px;
    overflow-y: auto;
  }
}
