.root {
  display: flex;
  width: 100%;
  padding: 17px 0 17px 35px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  text-decoration: none;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.root:hover {
  background: linear-gradient(
    270deg,
    rgba(18, 81, 160, 0.06) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.root.disabled:hover {
  background: initial;
  cursor: default;
}

:global(.active) .icon svg path {
  stroke: var(--blue-light);
}

:global(.active) .icon svg rect {
  stroke: var(--blue-light);
}

:global(.active).root {
  border-right: 5px solid var(--blue-light);
  color: var(--blue-light);
  background: linear-gradient(
    270deg,
    rgba(18, 81, 160, 0.06) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
