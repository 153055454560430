.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 466px;
  margin-top: 17px;
}

.root.isTabled {
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
}

.root.isMobile {
  width: 100%;
  box-sizing: border-box;
}

.heplerText {
  margin: 14px 0 41px 0;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: var(--gray);
}

.dropzone {
  margin-top: 11px;
}

.selectedUserList {
  display: flex;
  max-width: 400px;
  flex-wrap: wrap;
  gap: 7px;
}

.selectedUserWrapper {
  display: flex;
  width: 100%;
  margin-top: 49px;
}

.selectedUser {
  background: #e1f7ff;
  padding: 6px 7px;
  color: var(--blue-light);
  display: flex;
  max-width: 172px;
  min-width: 172px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}

.selectedUserName {
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedUserIcon {
  width: 12px;
  height: 12px;
}

.selectedUserIcon path {
  stroke: var(--blue-light);
}

.selectedUserIcon:hover {
  cursor: pointer;
}
