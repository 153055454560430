.root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  background: var(--white);
  flex-direction: column;
}

.text {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--blue-light);
  text-align: center;
}
