.root {
  padding-top: 110px;
  min-width: 278px;
  box-sizing: border-box;
  background: var(--white);
  display: flex;
  flex-direction: column;
}

.callButton {
  margin-top: 70px;
  margin-bottom: 40px;
}

.root.large {
  min-width: 360px;
}

.root.large .callButton {
  margin-top: 60px;
}

.root.isMobile {
  padding-top: 43px;
  min-width: 304px;
  overflow-y: visible;
}

.root.isMobile .callButton {
  margin-top: 20px;
  margin-bottom: 38px;
  margin-left: 10px;
}

.creatingNotificationButton {
  display: flex;
  min-height: 58px;
  width: 245px;
  align-items: center;
  justify-content: center;
  color: var(--black);
  border-radius: 10px;
  border: 1px solid #41c8f4;
  background: var(--white);
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  box-sizing: border-box;
}

.creatingNotificationButton:hover {
  background: var(--blue-light);
  color: var(--white);
  border: 1px solid var(--blue-light);
  cursor: pointer;
}

.root.large .creatingNotificationButton {
  margin-bottom: 20px;
}

.root.isMobile .creatingNotificationButton {
  margin-bottom: 20px;
}

@media (min-height: 980px) {
  .root {
    padding-top: 34px;
  }

  .root.large .callButton {
    margin-top: 32px;
    margin-bottom: 17px;
  }
}

@media (max-height: 800px) {
  .root {
    padding-top: 20px;
    padding-bottom: 40px;
    overflow-y: scroll;
  }

  .root .callButton {
    margin-top: 20px;
    margin-bottom: 17px;
  }
}

.root.isLaptop {
  overflow-y: visible;
  padding-top: 50px;
}
