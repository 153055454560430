.root {
  background: var(--white);
  box-shadow: -62px 36px 43px rgba(44, 81, 104, 0.01),
    -28px 16px 32px rgba(44, 81, 104, 0.02),
    -7px 4px 18px rgba(44, 81, 104, 0.02), 0px 0px 0px rgba(44, 81, 104, 0.02);
  padding: 6px 20px 6px 15px;
  width: 100%;
  box-sizing: border-box;
  border-top: 2px solid #f9fbfe;
}

.spoilerIcon {
  margin-left: 20px;
  margin-right: 20px;
}

.spoilerIcon path {
  stroke: #292d32;
}

.spoilerIcon.isOpen {
  transform: rotate(180deg);
}

.spoilerIcon:hover {
  cursor: pointer;
}

.selectedAccount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: #effbff;
}

.content {
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 12px;
}
