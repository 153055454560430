.root {
  width: 100%;
  background: var(--white);
  height: 50px;
  padding: 16px 12px;
  color: #090909;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #ececec;
  border-radius: 5px;
  font-family: 'Stolzl';
}

.root:focus {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #090909;
  border: 1px solid var(--gray);
  background: var(--white);
}

.root:disabled {
  background: #f9f9f9;
  border: 1px solid #ececec;
}

.auth {
  width: 100%;
  background: #f9f9f9;
  height: 48px;
  padding: 10px;
  border: none;
  color: #090909;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #f9f9f9;
  font-family: 'Stolzl';
}

.auth:focus {
  background: #f9f9f9;
  color: #090909;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #41c8f4;
  outline: none;
}

.root.error {
  border: 1px solid var(--secondary);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.root.isIOS {
  font-size: 16px;
  line-height: 20px;
}

.root:focus.isIOS {
  font-size: 16px;
  line-height: 20px;
}

.root.auth.isIOS {
  font-size: 16px;
  line-height: 20px;
}

.root:focus.auth.isIOS {
  font-size: 16px;
  line-height: 20px;
}
