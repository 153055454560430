.root {
  margin-top: 8px;
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary);
  width: 100%;
  box-sizing: border-box;
}
