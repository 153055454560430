.root {
  height: 150px;
  color: #090909;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #ececec;
  border-radius: 5px;
  font-family: 'Stolzl';
  padding: 16px 12px;
  resize: none;
  outline: none;
  box-sizing: border-box;
}

.root:focus {
  font-family: 'Stolzl';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #090909;
  border: 1px solid #ececec;
  border-radius: 5px;
  outline: none;
}

.fullWidth {
  width: 100%;
}

.root.isIOS {
  font-size: 16px;
  line-height: 20px;
}

.root:focus.isIOS {
  font-size: 16px;
  line-height: 20px;
}
