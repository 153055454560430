.wrapper {
  position: relative;
}

.root {
  display: flex;
  align-items: center;
  padding: 11px 16px;
  border: 1px solid var(--white);
  box-sizing: border-box;
  transition: border 253ms;
  min-width: 230px;
  max-width: 520px;
  overflow: hidden;
  word-break: break-all;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  background: #effbff;
}

.root:hover {
  border: 1px solid var(--blue-light);
  border-radius: 5px;
  cursor: pointer;
  transition: border 253ms;
}

.root.popoverOpened {
  border: 1px solid var(--blue-light);
  transition: border 253ms;
  border-radius: 5px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
  border-radius: 48px;
  background: #e1f7ff;
  margin-right: 13px;
}

.data {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userAddress {
  font-weight: 300;
  font-size: 14px;
  white-space: normal;
  width: 100%;
}

.userData {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  white-space: normal;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userData .userDataId {
  font-weight: 400;
  color: #41c8f4;
  margin-left: 5px;
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
