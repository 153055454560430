.root {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 26px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-height: 800px) {
  .root {
    padding-bottom: 0;
  }
}
